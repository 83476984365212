.cityPageContainer {
    padding: 0 3rem;
}

.cityPageForm {
    position: sticky;
    top: 120px;
}

.projectCount {
    font-size: 0.75rem;
    font-weight: 450;
    color: #6c757d;
}

.sorted-Filter {
    display: block;
    color: #000;
}

.sorted-Filter:hover {
    color: #fff;
}

@media screen and (max-width: 768px) {
    .cityPageContainer {
        padding: 0.75rem;
    }

    .cityPageForm {
        margin-top: 2rem 0.5rem;
    }

    .cityPageLeftSideContainer {
        padding: 0.5rem;
    }
}