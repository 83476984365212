.grid-container {
    display: grid;
    background-color: rgba(255, 255, 255, 0.4);
    grid-gap: 0.063rem;
}

@media screen and (min-width: 28.75rem) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media screen and (min-width: 43.75rem) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-item:nth-child(2),
    .grid-item:nth-child(3),
    .grid-item:nth-child(6),
    .grid-item:nth-child(7) {
        grid-column: span 2;
    }
}

@media screen and (min-width: 61rem) {
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .grid-item:nth-child(2),
    .grid-item:nth-child(3),
    .grid-item:nth-child(6),
    .grid-item:nth-child(7) {
        grid-column: span 1;
    }
}

/* Grid item styles */
.grid-item {
    background-color: #2f2f2f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.875rem;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
}

.grid-item-content {
    display: grid;
    place-items: center;
}

.grid-item img {
    width: clamp(7.5rem, 40vw, 10.625rem);
    height: clamp(7.5rem, 40vw, 10.625rem);
    border-radius: 50%;
    object-fit: cover;
    transition: 0.25s ease-in-out;
}

.grid-item h3 {
    text-transform: capitalize;
    font-weight: 700;
    font-style: normal;
    font-size: clamp(1.25rem, 1.1667rem + 0.381vw, 0.5rem);
    margin: 1.1em 0 0.625rem 0;
}


.grid-item span {
    font-size: 0.9rem;
    color: #a3a3a3;
}


.grid-item {
    &.active {
        background: #f37535;
        ;
        color: #111;

        span {
            color: #111;
        }

        img {
            width: clamp(5rem, 60vw, 10rem);
            height: clamp(5rem, 60vw, 10rem);
        }
    }
}