.patchContainer {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;

    .patchCard {
        width: 160px;
        height: 150px;
        padding: .5rem;

        & img {
            width: 100%;
            height: 100%;
            padding: 1rem 0;
        }
    }
}