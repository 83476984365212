.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    min-height: 800px;
}

.login-popup-content {
    background-color: #fff;
    padding: 1.25rem;
    border-radius: 0.25rem;
    width: 300px;
}

.login-popup-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.logoOnLoginForm {
    width: 120px;
}

.closeBtn {
    border: none;
    height: 100%;
    padding: 0.5rem 0.75rem;
    color: #ef750f;
    font-weight: 700;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
}

.closeBtn:hover {
    color: #000;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.formModeSwitcher {
    color: #333;
    font-size: 13px;
}

.formModeSwitcher span {
    cursor: pointer;
    color: #ef750f;
}