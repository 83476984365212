/*  */
.eventTimeZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 1rem; */
  gap: 1rem;
}

.iconTextWrapper {
  display: flex;
  align-items: start;
  flex-direction: column;
  /* gap: 1rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.iconContainer {
  display: flex;
  justify-content: start;
  align-items: start;
  font-size: 1rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.textTitle {
  font-weight: 800;
  font-size: 1.2rem;
}

.textDescription {
  font-size: 0.9rem;
  color: #666; /* Adjust color as needed */
  margin-left: 10px;
}
