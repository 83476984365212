.sticky-icon {
    z-index: 1;
    position: fixed;
    top: 40%;
    transform: rotate(180deg);
    width: 112px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
}

.sticky-icon a {
    display: flex;
    align-items: center;
    transform: translate(70px, 0px);
    border-radius: 50px 0px 0px 50px;
    text-align: left;
    margin: 2px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.25rem;
    padding-right: 1rem;
    font-size: 1rem;
    font-family: "Oswald", sans-serif;
    transition: all 0.8s;
    pointer-events: all;
    cursor: pointer;
}

.sticky-icon span {
    font-size: 0.75rem;
}

.sticky-icon a:hover {
    color: #fff;
    transform: translate(40px, 0px);
}

/*.search_icon a:hover i  {
    transform:rotate(360deg);}*/
.video {
    background-color: #2c80d3;
    color: #fff;
}

.Youtube {
    background-color: #fa0910;
    color: #fff;
}

.Twitter {
    /* background-color: #53c5ff; */
    background-color: #14171A;
    color: #fff;
}

.whatsapp {
    background-color: rgb(37, 211, 102);
    color: #fff;
}

.envelope {
    background-color: #d34836;
    color: #fff;
}

.sticky-icon a i {
    background-color: #fff;
    height: 1.75rem;
    width: 1.75rem;
    color: #000;
    text-align: center;
    line-height: 1.75rem;
    border-radius: 50%;
    margin-right: 1.25rem;
    transition: all 0.5s;
}

.sticky-icon a i.fa-video {
    background-color: #fff;
    color: #2c80d3;
}

.sticky-icon a i.fa-envelope {
    background-color: #fff;
    color: #d34836;
}

.sticky-icon a i.fa-whatsapp {
    background-color: #ffffff;
    /* color: #FD1D1D; */
    color: green;
}

.sticky-icon a i.fa-youtube {
    background-color: #fff;
    color: #fa0910;
}

.sticky-icon a i.fa-twitter {
    background-color: #fff;
    color: #53c5ff;
}

.fas fa-shopping-cart {
    background-color: #fff;
}

#myBtn {
    height: 50px;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    text-align: center;
    padding: 10px;
    text-align: center;
    line-height: 40px;
    border: none;
    outline: none;
    background-color: #1e88e5;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}

.fa-arrow-circle-up {
    font-size: 30px;
}

#myBtn:hover {
    background-color: #555;
}

.rotate-logo {
    transform: rotate(180deg);
}

.textSocial {
    display: inline-block;
    transform: rotate(180deg);
}

span.textSocial {
    display: none;
}

.sticky-icon a:hover i {
    transform: rotate(180deg);
}