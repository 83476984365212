.subscribe-section {
  /* padding: 98px; */
  background-color: rgba(243, 243, 243, 1);
}

/* .subscribe-section h2 {
  font-size: 2rem;
} */

.btn-orange {
  background-color: rgba(239, 117, 15, 1);
  color: #fff;
  border: none;
  font-size: 1rem;
}

/* .image-grid.df {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */
