.aboutBuilderContainer {
    background: #F8F8F8;
    border-radius: 1rem;
    /* overflow-y: auto; */
    padding: 1rem;
}

.builderImgBox {
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8.5rem;
    max-height: 8.5rem;
    overflow: hidden;


    & img {
        width: 100%;
        height: 100%;
        /* object-fit: cover; */
    }
}

.aboutBuilderInfo {
    display: flex;
    justify-content: space-between;
    /* margin: 1rem 0; */

    .labelExperience,
    .labelProject {
        font-weight: 600;
        font-size: 1.25rem;
    }

}

.builderDescription {
    /* max-height: 6rem; */
    max-height: 22.5rem;
    overflow-y: hidden;
    /* transition: .9s; */
}

.builderDescription_active {
    max-height: 100%;
}

@media(width<=576px) {
    .aboutBuilderInfo {
        display: flex;
        flex-direction: column;


        .labelExperience,
        .labelProject {
            font-size: 1rem;
        }
    }


}