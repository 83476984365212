.searchBarPopUp {
    position: absolute;
    display: flex;
    height: 2.5rem;
    top: 110px;
    right: 1rem;
    width: 364px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    background-color: white;
    border-radius: .25rem;
    padding-left: .1rem;

    @media screen and (max-width:768px) {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 0.25rem);

    }
}


.searchBarPopUp input {
    width: 100%;
    border: none;
    border-radius: .25rem 0 0 .25rem;
}

.searchBarPopUp .button {
    right: 0;
    color: #fff;
    border: none;
    padding: 0 1rem;
    background-color: #ef750e;
    cursor: pointer;
    border-radius: 0 .25rem .25rem 0;
}