.achievements {
  /* display: flex;
  height: 300px; */
  background: #000000;
  /* border-radius: 1rem; */
  /* background-color: #000; */
  /* padding: 1.75rem 0; */

  @media screen and (max-width: 768px) {
    background: #000000;
  }
}

.achievements .col-md-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.achievements img {
  width: 7.25rem;
  margin: 1.2rem;
  padding: 0.5rem;

  @media screen and (max-width: 768px) {
    width: 4rem;
    margin: 0.25rem;
    padding: 0.25rem;
  }
}

.achievements img:hover {
  transform: scale(0.9);
  transition: ease-in-out 0.5s;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #fff;
  box-shadow: 0px 0px 8px rgba(255, 255, 255);

  @media screen and (max-width: 768px) {
    border-radius: 0.5rem;
  }
}

.achievementsImgText {
  font-size: 1.2rem;
  color: white;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 0.75rem;
  }
}
