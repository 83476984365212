.bg-vision {
  width: 80%;
  height: auto;
  animation: floatAnimation 5s infinite linear;
}

.bg-vision:hover {
  animation-play-state: paused;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
    /* Initial position */
  }

  50% {
    transform: translateY(-20px);
    /* Float up */
  }

  100% {
    transform: translateY(0);
    /* Return to initial position */
  }
}
.ourVisionImageBg {
  background-image: url("../../../../assets/giphy.gif");
  background-size: contain;
  background-repeat: no-repeat;
}

.visionSecBg1 {
  background-color: #f2cbc5;
  padding: 2rem;
  border-radius: 24px;
  overflow: hidden;
}

.visionTitle {
  font-size: 2rem !important;
  font-weight: 700;
  border-bottom: 6px solid #e28a7c;
  display: inline;

  @media (width<=575px) {
    font-size: 1rem !important;
  }
}

.visionPara {
  text-align: justify;
  padding: 1rem;
}

.iconPlacement {
  float: right;
}

/* .mtb {
    margin-top: 60px;
    margin-bottom: 60px;
} */

@media screen and (max-width: 768px) {
  .ourVisionImageBg {
    background: none;
    width: 100%;
    padding: 1.5rem 0 0 0;
  }

  .visionSecBg1{
    padding: 1rem;
  }

  .visionTitle {
    border: none;
  }

  .visionPara {
    padding:0;
  }
}
