.disclaimerContent ul {
    color: #888;
    list-style-type: decimal;
    padding: 5rem 2rem 4rem 2rem;
}



.disclaimerContent ul li {
    margin-bottom: 1.25rem;
    color: #888;
    margin-left: 2.5rem;
}

.policyHeading {
    margin-bottom: 1.5rem;
}