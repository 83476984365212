.formFooterImgBox {
    width: 7rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: rgba(243, 117, 53, 0.1);
    padding: .25rem;
    border-radius: .25rem;

    & img {
        padding: .25rem;
    }
}

.formFooterText {
    margin-top: 1rem;
    color: #555;
    text-align: center;
    font-size: .75rem;
    line-height: 1rem;
    /* padding: 0 .25rem; */
}