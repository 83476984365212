.marquee-cards-container {
  position: relative;
  /* padding-top: 40px; */
  /* background-color: #f7f7f7; */
  width: 100%;
  margin-top: 54px;
  padding-bottom: 0px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  z-index: 0;
}

.marqueeCard {
  padding: 1rem;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;
}

.marqueeCard img {
  /* width: 100%; */
  width: 130px;
  height: auto;
}

.client-head {
  padding-top: 18px;
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
  font-weight: 800;
  line-height: 1.2;
  max-height: calc(2.5em);
  overflow: hidden;
  text-align: center;
}

.bgFinalClient {
  position: relative;
  padding: 48px;
  /* padding: 23px; */
  background-color: #f7f7f7;
}
.center-itemheading {
  font-size: 2.5rem;
  line-height: 1.2;
  max-height: calc(2.5em);
  text-align: center;
}
