@media (max-width: 768px) {
  div#root {
    overflow: hidden !important;
    padding: 0px;
    margin: 0px;
  }

  .footer-logo {
    margin-bottom: 20px;
  }

  .footer-content {
    flex-direction: column;
  }

  .bi-search::before {
    content: "\f52a";
    position: absolute;
    left: 19rem;
    color: black;
    width: 1rem;
    height: 1rem;
    top: 13px;
  }

  .bi-suit-heart::before {
    content: "\f59e";
    position: absolute;
    left: 21rem;
    color: black;
    width: 1rem;
    height: 1rem;
    top: 14px;
  }

  .bi {
    color: #ed7233;
    margin-right: 13px;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 20px;
  }

  .newsletter {
    flex-direction: column;
    align-items: stretch;
  }

  .newsletter input {
    width: 100%;
    margin-bottom: 10px;
  }

  .newsletter button {
    width: 100%;
  }

  /* Global Styles */
  /* footer {
    background-color: #f8f9fa;
    padding: 20px 0;
  }

  .footer-logo {
    max-width: 100%;
    height: auto;
  } */

  /* .contactWithUs {
    list-style-type: none;
    padding: 0;
  } */

  /* .contactWithUs h3.contact_us {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: center;
    color: #f37535;
    margin-bottom: 20px;
    position: relative;
  } */
  /* 
  .contactWithUs h3.contact_us::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background: #f37535;
    margin: 10px auto 0;
  } */

  .height {
    height: 100px;
  }

  .form-control {
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    color: #8e8e8e;
  }

  /* .menuBg {
    background-color: #000000;
    color: #fff;
    padding: 20px 0;
  } */

  /* ul.contactWithUs {
    padding-left: 0px;
  } */

  /* .dailDeco {
    margin-left: 101px;
    color: #fefefe !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    text-decoration: none;
  } */

  .menuBg .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  /* .menuBg .wc-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -61px;
  } */

  /* .footer-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  } */

  /* .menuBg .wc-container .col-md-6,
  .menuBg .wc-container .col-sm-6 {
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;
  } */

  .c-gray {
    font-size: 9px;
  }

  /* 
  .menuBg .category {
    list-style-type: none;
    padding: 0;
    margin-left: 6px;
  } */

  /* .menuBg .heading-footer {
    color: #f37535;
    margin-bottom: -13px;
    font-size: 14px;
    margin-bottom: -3px;
    font-weight: 600;
    line-height: 23.94px;
    text-align: left;
    margin-left: 4px;
  }

  .menuBg .category a {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    text-decoration: none;
  }

  .menuBg .category a:hover {
    text-decoration: underline;
  } */

  /* .footer-logo {
    margin-bottom: 0px;
    height: 60px;
    width: 126px;
    margin-left: 134px;
    height: 60px;
    top: 4926px;
    left: 134px;
    gap: 0px;
  } */

  .callback {
    padding: 12px 24px;
    text-align: center;
    /* color: rgba(255, 255, 255, 1) !important; */
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16.94px !important;
  }

  /* 
  .vertical-center {
    display: flex;
    align-items: center;
  } */

  /* .dr {
        margin-bottom: 1px;
        height: 532px;
        background-image: url("../../assets/image\ 39.png");
        background-size: cover;
        background-repeat: no-repeat;
    } */

  .companyWork {
    padding: 2rem 0;
    margin: 0rem 0 !important;
    background: rgba(0, 0, 0, 0.7)
      url(https://knowledge.wharton.upenn.edu/wp-content/uploads/2023/07/7.12.23-wachter-corporate-real-estate-GettyImages-136333671.jpg);
    background-blend-mode: darken;
    background-attachment: fixed;
  }

  /* .my-5 {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }

    .mt-5 {
        margin-top: 0rem !important;
    } */

  .gk::after {
    content: "";
    display: block;
    width: 350px;
    height: 2px;
    background: #575757;
    margin: 14px auto 0;
  }

  .hj {
    background: #ebebeb;
  }

  .btn-primary {
    border: #f37535;
    background: #f37535 !important;
  }

  .gh {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 14.52px;
    color: #b7b7b7;
  }

  .gy {
    font-size: 11px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
    color: #b7b7b7;
  }

  .centerSlider {
    justify-content: flex-start !important;
  }

  .gk {
    margin-left: 14px;
    font-size: 12px;
    color: #575757;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
  }

  .ku {
    padding: 12px;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16.94px !important;
    color: white !important;
  }

  .image-container {
    background-image: url("../../assets/homePageImg/Mob_Orion_Banner.jpg");
    /* background-image: url("../../assets/image 45.png"); */
    /* background-image: url("../../assets/homePageImg/WC-Independence-Day-Banner-\(Mobile\).jpg"); */
    /* background-position: center; */
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    /* height: 40vh; */
    height: 340px;
    /* top: 84px;
        gap: 0px; */
  }

  /* 
    .mobileTemplateYu {
        
        line-height: 24.2px;
        text-align: left;
        font-size: 1.75rem !important;
        font-weight: 600;
        text-align: center;
        color: white;
        margin-top: 170px !important;
    } */

  /* .mobileTemplateKj {
        
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        margin-top: -45px;
        font-size: .75rem !important;
        text-align: center;
        color: white;
    } */

  .btn1 {
    color: white;
    background: #f37535;
    right: -4px !important;
    border: azure;
    position: absolute;
    right: -3px;
    top: 0;
    height: 100%;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
  }

  .heading {
    margin-top: 5px;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 2rem !important;
    color: #f37535 !important;
  }

  .text-wrap {
    word-wrap: break-word !important;
    word-break: break-word !important;
    white-space: normal !important;
  }

  /* .formbold-mb-5 {
        margin-bottom: 7px;
    } */

  /* 
    .formbold-main-wrapper {
        padding: 0px;
    } */

  .card::before {
    content: "";
    position: absolute;
    background: none;
  }

  .card {
    margin-bottom: 10px;
    position: relative;
    width: 9.25rem;
    height: 182px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    gap: 12px;
    border-radius: 7px;
    cursor: pointer;
    background-size: cover;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.3);
  }

  nav a:not(:only-child)::after {
    content: "+";
    position: absolute;
    right: 1em;
    display: none;
  }

  .callback2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    color: #e6e6e6;
  }

  /* about us  */
  .Wc-mission {
    width: 353px;
    margin-top: 29px;
    border-radius: 21px;
    height: 236px;
  }

  .main-sec {
    width: 100%;
    height: 34vh;
    display: table-row;
  }

  .img img {
    border-radius: 50%;
    border-radius: 10px;
    margin-top: 14px;
    width: 156px;
    height: 153px;
  }

  .Wc-mission:hover {
    border-radius: 0px;
  }

  .main-sec {
    height: 29vh;
  }

  .caption {
    transform: none !important;
    opacity: revert !important;
    transition: none !important;

    margin-top: 2px !important;
  }

  .caption p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    color: #525558;
  }

  .top-bottom-team {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .about-mobile {
    background-color: rgb(214, 223, 217);
  }

  .bor-down {
    text-align: center !important;
    color: #525558 !important;
    font-size: 12px !important;
    font-style: italic !important;
    font-weight: 400 !important;
    line-height: 14.52px !important;
    border-bottom: 2px solid #ef7a17 !important;
  }

  .OurTeamtitle {
    color: #ef7a17 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 21.78px !important;
    /* text-align: left !important; */
    font-weight: 700 !important;
    text-align: center !important;
    padding-top: 9px !important;
  }

  /* .visionTitle {
        font-weight: 400;
        border-bottom: none;
        display: inline;
        font-size: 14px !important;
        text-align: center !important;
        color: #ef7a17 !important;
    } */

  /* .ourTeam1 {
        border-radius: 0 .75rem;
    } */

  .bgDirector {
    position: relative;
    background: none;
    border-radius: none;
  }

  /* .ourTeam1 {
        margin-top: 0px !important;
    } */

  .fullDescription {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.52px !important;
    letter-spacing: 0.02em !important;
    text-align: justify !important;
    color: #525558 !important;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
    padding-top: -24px;
  }

  button.fw-bold {
    font-size: 10px;
    /* margin-top: 10px; */
  }

  /* .visionSecBg1 {
        background: none;
        padding: 6px;
        border-radius: none;
    } */

  /* .visionTitle {
        margin-top: -4px;
        font-size: 14px important;
        font-weight: 600 important;
        line-height: 16.94px important;
        text-align: left important;
        font-weight: 700 important;
        border-bottom: none important;
        display: inline important;
    } */

  /* .ourVisionImageBg {
    background-image: none !;
    background-size: contain;
    background-repeat: no-repeat;
  } */
  /* 
    .mtb {
        margin-top: 23px;
        margin-bottom: 0px;
    } */

  /* .bg-vision {
    width: 214px;
    height: 220px;
    animation: floatAnimation 5s infinite linear;
  } */

  /* .visionPara {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: -0.002em;
    text-align: left;
    margin-top: -4px;
  } */

  .via {
    /* width: 393px; */
    /* background: #f2cbc5; */
  }

  /* .ourMissionTitle {
        position: absolute !important;
        font-weight: 700 !important;
        border-bottom: none !important;
        color: #ef7a17 !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 16.94px !important;
        margin-top: -36px;
        left: 119px;
    } */

  .bg-mission {
    width: 212px;
    height: 220px;
    top: 928px;
    left: 87px;
    gap: 0px;

    animation: floatAnimation 5s infinite linear;
  }

  /* .our {
        background: #dbd9ed !important;
    } */

  /* .ourMissionImgBox {
    background: none;
    width: 100%;
    height: 212.5px;
    border-radius: 214px;
    background-size: contain;
    background-repeat: no-repeat;
  } */

  /* .ourMissionPara {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        letter-spacing: -0.02em;
        text-align: justify;
        margin-top: -23px;
        text-align: justify;
        color: #525558;
    } */

  /* .WcSecBg1 {
        font-size: 14px;
        font-weight: 400;
        line-height: -15.48px;
        letter-spacing: -0.01em;
        padding: 31px;
        background: none !important;
    } */

  /* .Wcpara {
        margin-top: -19px;
        text-align: justify;
        font-size: 14px;
        font-weight: 400;
        line-height: 14.52px;
        letter-spacing: -0.005em;
    } */

  /* contact us */
  div.text-background-container {
    font-size: 30%;
    display: none;
  }

  /* iframe {
        width: 235px;
    } */

  /* .my-4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        text-align: left;
        color: #ef7a17;
        text-align: center;
    } */

  .span {
    margin-left: 7px;
  }

  /* .ourEthos {
        background: #f0f0f0;
        width: 393px;
        height: 780px;
    } */

  /* .bgCards {
        width: 353px;
        height: 74px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 10px;
    } */

  /* .aboutImg {
        position: absolute;
        width: 144px;
        height: 180px;
        top: 342px;
        left: 240px;
    } */

  /* .paraDesc {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        margin: -9px;
    } */

  /* .bgNumber {
        font-size: 23px;
        font-weight: 900;
        margin: 8px;
        color: #b0b0b0;
    } */

  /* Events */
  .carousel {
    padding: 0px;
    background: none;
    text-align: center;
    margin-bottom: 0px;
  }

  .carousel img {
    width: 218px;
    height: 166px;
  }

  .carousel span {
    display: inline-block;
    word-spacing: 1em;
    overflow: auto;
    white-space: nowrap;
    margin-top: 42px;
    word-wrap: normal;
  }

  .carousel img {
    width: 164px;
    height: 166px;
  }

  .carousel a {
    border: 1px solid #ef750e;
  }

  /* AWARD  */
  .awardsCard img {
    width: 183px;
    height: 241px;
    margin-left: 31px;
  }

  .awardsCard {
    width: 274px;
    margin-top: 8px;
    height: 300px;
    margin-bottom: 12px;
    border-radius: 8px;
    background: white;
    justify-content: center;
  }

  .awardsCardImgBox {
    width: 183px;
    height: 176px;
  }

  .m-5.awardHeading {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 14.52px !important;
    text-align: center !important;
    color: #2d2d2d !important;
  }

  /* .p-5 {
        padding: 0rem !important;
    } */

  .eventCardContainer a {
    display: flex;
    text-decoration: none;
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
  }

  /* a.col-lg-4.col-md-4.col-sm-6.p-0.m-0 {
        width: 50%;
    } */

  .eventHeader a {
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    font-size: 11px;
    padding-left: 0.5rem;
  }

  .events {
    margin-right: 0.5rem;
    text-align: center;
    width: 340px;
    height: 33px;
    margin-top: 12px;
    cursor: pointer;
  }

  /* BLOGS */

  .categories-container {
    width: 353px;
    height: 40px;
    margin-left: 10px;
    margin-bottom: 12px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px #00000040;
  }

  .categories-heading {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 14.52px !important;
    text-align: left;
  }

  i.bi.bi-bookmark.fs-5.p-2 {
    display: none;
  }

  .side-bar-container {
    width: 78px;
    height: 76px;
    padding: 0.25rem;
  }

  .side-bar-main {
    background: white;
    border-radius: 10px;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid rgb(255 255 255);
  }

  .blogImgContainer {
    padding: 0px;
  }

  .blogPad {
    padding: 4px 15px 15px 15px;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.1px;
    letter-spacing: 0.01em;
    text-align: justify;
  }

  .h2,
  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #2d2d2d;
  }

  h3,
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    /* text-align: left; */
  }

  .h2,
  h2::before {
    display: inline-block;
    width: 1em;
    margin-right: 5px;
    color: #f37535;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
  }

  .form-control {
    width: 325px;
    height: 57px;
    border-radius: 6px;
    margin-left: 11px;
  }

  section.comments-section.mt-4 {
    background: white;
    width: 351px;
    height: 325px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .form-control {
    width: 325px;
    height: 57px;
    border-radius: 6px;
    margin-left: 11px;
  }

  .form-label {
    margin-left: 4px;
    font-size: 12px;
  }

  /* goole review */
  .huflDf {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16.94px !important;
    text-align: center !important;
    color: #ef7a17 !important;
  }

  .fXNqIK {
    width: 353px;
    height: 98px;
    background: #ef750f1a !important;
    border-radius: 6px;
  }

  .eIMmgT,
  .eIMmgT * {
    font-size: 8px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    box-sizing: border-box !important;
    outline: none !important;
  }

  .igQsGp img,
  .igQsGp svg {
    width: 98px;
    height: 32px;
    display: block;
  }

  button.btn.btn-sm.btn-shadow-card {
    display: none;
    margin: 0.25rem 0.5rem;
  }

  .headerStyle {
    display: none;
    margin-top: 0.25rem;
    background-color: #000;
  }

  .centerSlider {
    margin-bottom: -196px;
  }

  .px-5 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .propertyMenuDetails {
    /* background-color: #0b2c3d;
        color: #fff;
        display: flex;
        flex-direction: row; */
    /* Horizontal direction */
    justify-content: flex-start;
    /* align-items: center; */
    /* border-radius: 0.5rem; */
    /* padding: 0.5rem 0; */
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    /* scrollbar-color: #888 transparent; */
  }

  /* .propertyMenuDetails::-webkit-scrollbar {
        height: 8px;
    } */

  /* .propertyMenuDetails::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    } */

  /* .propertyMenuDetails::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    } */

  /* .propertyMenuDetails button {
        margin: 0 0.5rem;
        display: inline-block;
    }
    */

  .propertyMenuDetails div {
    font-size: 0.75rem;
  }
}

.search {
  position: relative;
}

.search input {
  background: #d9d9d9;
  width: 233px;
  height: 40px;
  top: 5175px;
  left: 20px;
  gap: 0px;
  border-radius: 6px 0px 0px 6px;
}

.search button {
  position: absolute;
  right: 110px;
  top: 0;
  height: 100%;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
}
