.masonryCard:hover {
  transform: scale(0.9);
  transition: 0.5s;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.15);
  filter: brightness(0.75);
}

.masonryCardContainer {
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.cardContainer {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.cardContainer:hover {
  transform: scale(1.05);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  padding-top: 40px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupImage {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
