.bgColBlock {
    width: 50%;
    position: relative;
    margin: 1.5rem;
    border-radius: 1.25rem;
    box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.3);
    padding: 1rem;
    padding-top: 0rem;

    @media screen and (max-width: 768px) {
        width: 90% !important;
        margin: .5rem;
    }
}

.readMoreTitle {
    text-align: justify;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
}

.readMoreDesc {
    color: #ffffff;
    line-height: 1.5rem;
    font-size: 14px;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: auto;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    /* max-height: 380px; */
    /* max-height: calc(1.5em * 6); */
}

.expandedDescription {
    display: block;
    -webkit-line-clamp: unset;
}

.readMoreDesc::-webkit-scrollbar {
    display: none;
}

/* !------old css for Black card-------------------- */

.pillarsImg {
    padding-top: 1.5rem;
    margin: 12px;
    width: 46px;
    height: auto;

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        margin-top: -1rem;
    }
}


.btnTxt {
    font-weight: 600;
}

.tryBubble {
    top: 0px;
    right: 0px;
    width: 144px;
    position: absolute;
}