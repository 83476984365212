.breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 1rem;
    text-transform: capitalize;
    font-size: 0.75rem;
    font-weight: 500;
    height: 100%;
}

.breadcrumb a {
    text-decoration: none;
    color: black;
    position: relative;
    padding: 0 0.5rem;
    word-spacing: 0.25rem;
}

.breadcrumb a:hover {
    color: var(--global-text-color);
}

.breadcrumb .currentRoute {
    color: var(--global-dark-btn-color);
}

.breadCrumb-item {
    display: flex;
    align-items: center;
}

.chevron-icon {
    margin: 0 0.5rem;
}