@media screen and (max-width: 768px) {

    .mobOurFootPrints {
        padding: 1rem .5rem;
        background-color: #000;
    }

    .OurFootPrintsTitle {
        color: #ef750f;
        font-size: 1rem;
    }

    .footprintsParagraph {
        letter-spacing: 1px;
        color: #fff;
        text-align: justify;
    }
}