.bg-mission {
  width: 80%;
  height: auto;
  animation: floatAnimation 5s infinite linear;
}

.bg-mission:hover {
  animation-play-state: paused;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.ourMissionTitle {
  font-size: 2rem !important;
  font-weight: 700;
  border-bottom: 6px solid #7b6fe7;
  display: inline;

  @media (width<=575px) {
    font-size: 1rem !important;
  }
}

.ourMissionPara {
  margin-top: 2rem;
  text-align: justify;
}

.missionSecBg1 {
  background-color: #dbd9ed;
  padding: 2rem;
  border-radius: 28px;
  overflow: hidden;
}

.missionIcons {
  float: right;
}

.iconPlacement {
  float: right;
}

/* .mtb {
    margin-top: 60px;
    margin-bottom: 60px;
} */

.ourMissionImgBox {
  background-image: url(../../../../assets/mission.gif);
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .ourMissionTitle {
    border: none;
  }

  .ourMissionPara {
    margin: 0;
  }

  .ourMissionImgBox {
    background: none;
    width: 100%;
    padding: 1.5rem 0 0 0;
  }
}
