.ethosCardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 220px;
  border: 2px solid #ef750f;
  /* background-color: #ee8c64; */
  border-radius: 1rem;
  /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6); */
  padding: 1rem;
}

/* .ethosCardContainer:hover {
  background-color: #ef750f;

  border-radius: 0.75rem;
  transition: border-radius 2s ease-in-out;
} */

.ethosIcon {
  width: 70px;
  height: auto;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 12px;
}

.ethosTitle {
  width: 100%;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #ef750f;
}

.ethosDescription {
  width: 100%;
  padding: 0 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  color: #000;
}

@media screen and (max-width: 768px) {
  .ethosCardContainer {
    height: 154px;
    border-radius: 10px;
    padding: 0.25rem;
  }

  .ethosIcon {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 0.75rem;
  }

  .ethosTitle {
    font-size: 12px;
    font-weight: 600;
    color: #000;
  }

  .ethosDescription {
    font-size: 10px;
    margin-top: -15px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: #444;
    padding: 0 0.25rem;
  }
}
