.faqContainer {
    height: 400px;
    overflow-y: auto;
}

.faq-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin: 1rem 0;
    padding: 1rem;
    /* border: 2px solid red; */
}

.questionBox,
.answerBox {
    padding: 0.25rem 0;
    display: flex;
    align-items: center;
}

.faq-label {
    color: #ef750f;
    padding-right: 1rem;
    font-weight: 600;
}

.faq-question {
    flex: 1;
    font-weight: 500;
}

.faq-answer {
    flex: 1;
    color: #666;
}

.faq-divider {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
}

.answerBox .faq-label {
    color: #48b02c;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .faq-box {
        padding: 0.5rem;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    }

    .faq-label {
        font-size: 0.9rem;
    }

    .faq-question,
    .faq-answer {
        font-size: 1rem;
    }
}