.amenitiesBox {
    max-height: 400px;
    overflow-y: auto;
}

.amenitiesSubCategory {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    & img {
        border: 1px solid #ef750f;
        border-radius: 0.25rem;
        object-fit: cover;
        width: 40px;
        padding: 0.25rem;
        margin: 0.25rem;
        margin-right: 1rem;
    }

    .amenitiesText {
        font-size: 0.75rem;
        font-weight: 450;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}