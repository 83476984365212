.headerContainer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: 1rem;
}
.newHeaderStyle {
  position: relative;
}

/* ----------------------- */
.projectDetailsForm {
  padding: 0.75rem;
  border-radius: 0.5rem;
  position: sticky;
  top: 108px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.contactExpertForm {
  padding: 0 !important;
}

/* .projectBreadCrumbBox {
    border-radius: 0.25rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 100%;
    height: 42px;

    & .projectBreadCrumb {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;


        & .breadcrumb {
            position: relative;
            transform: translate(10px, 10px)
        }
    }
} */
