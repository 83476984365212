.advancedSearchBar {
    display: flex;
    /* width: 850px; */
    width: 75%;
    height: 40px;

    border-radius: 1rem;
    background-color: white !important;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    /* overflow: hidden; */
    pointer-events: auto;
}

.advancedSearchBar div .filterSearchBtn {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0 0.4rem 0.4rem 0;
}

.advancedSearchBar div input {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 0.75rem;
}

.btn-width {
    width: 20%;
}

.searchBtn {
    color: #fff;
    text-decoration: none;
}

.searchBar {
    position: relative;
    z-index: 9999999;
    height: 100%;
    /* width: 40%; */

    .searchBarLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        background: white !important;
        width: 50px;
        height: 100%;
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.4);
    }

    .inputSuggestions {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: white;
        transition: 0ms;
        border-radius: 0 0 0.75rem 0.75rem;
        padding: 0 0.25rem;
        height: 350px;
        overflow-y: scroll;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    }

    .inputSuggestions li {
        text-decoration: none;
        list-style-type: none;
        cursor: pointer;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0.25rem;
        padding: 0.5rem 0;
        padding-left: 1rem;
        transition: 0;
        color: #000;
        border-radius: 0.25rem;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .inputSuggestions li:hover {
        background-color: #000;
        color: #fff;
        transition: 0s;
    }
}