.projectHighlight,
.overview {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 1rem;
}

.overview {
    margin-top: 1rem;
}

.reraBox {
    padding: 0.5rem 0;

    .reraId,
    .reraNumber {
        text-align: center;
        font-weight: 500;

        padding: 0.5rem 0;
    }

    .reraId {
        background-color: #ef750f;
        color: #fff;
    }

    .reraNumber {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0 0 0.5rem 0.5rem;
    }
}

.highlightDetails {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

@media(width<=576px) {

    .projectHighlight,
    .overview {
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        padding: 1rem;
    }
}