/* .BlogBgColor {
  background-color: rgb(245, 245, 245);
  border-radius: 13px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0 1rem;
} */

.blogImgContainer {
  padding: 1.5rem 1rem 1rem 1rem;
}

.imgForBlog {
  border-radius: 0.75rem;
  position: relative;
}

.imgBlogStyle {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
}

.secDate {
  padding: 11px;
  position: absolute;
  bottom: 8%;
  left: 5%;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  border: 1.5px solid #fff;
  color: #fff;
  position: absolute;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
section.blogPad {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.6px;
  text-align: left;
  padding: 9px 0px 12px 24px;
  margin-top: 0;
}
section.blogPad p {
  font-family: Segoe;
}
.dateBlog {
  text-align: center;
  font-size: 32px;
  margin-bottom: 4px;
}
section.blogPad h1,
section.blogPad h2 {
  font-family: Segoe !important;
  font-weight: 600 !important;
}

section.blogPad ul li {
  /* font-weight: bold; */
  font-family: Segoe !important;
}

section.blogPad h3,
section.blogPad h4 {
  font-family: Segoe !important;
  font-weight: 600;
}

.monthBlog {
  text-align: center;
  font: 16px;
}

.ProfileIcon {
  font-size: 20px;
  margin: 5px;
}

.ProfileBlog {
  margin-bottom: 2px;
}

.leftRightSpace {
  margin-left: 40px;
}

.blog-btn-box {
  border: 1px solid rgba(245, 245, 245, 0.5);
  margin: 0 0.25rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  color: #fff;
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  background-color: rgba(229, 117, 15, 0.8);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
}

.blog-btn-icons {
  font-size: 1.25rem;
  margin-left: 0.25rem;
  cursor: pointer;
}

.inside-blog-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-btns-container {
  display: flex;
  position: absolute;
  top: 5%;
  right: 5%;
}

.display-comments {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  /* .blogImgContainer {
        height: 300px;
        padding: 1.5rem 1rem 1rem 1rem;
    } */

  .secDate {
    display: none;
  }

  .blog-btn-box {
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
}

.dateContainer {
  display: flex;
  flex-direction: row;
}
.dateContainer p {
  margin: 0;
  padding-right: 5px;
  font-size: 16px;
}

.dateBlog {
  /* font-weight: 600; */
  font-size: 18px;
}

.monthBlog {
  font-weight: 400;
  font-size: 14px;
}
