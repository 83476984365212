.projectImageSliderBox {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  /* padding: "1rem"; */
  border-radius: 1rem;
}

.slider-container {
  border-radius: 0.5rem;
  position: relative;

  .controlBtnBox {
    pointer-events: none;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;

    .leftBtn,
    .rightBtn {
      pointer-events: all;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 1);
      color: #ef750f;
      line-height: 1;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.projectDetailsIcons {
  font-size: 1rem;
  color: #ef750f;
  margin: 0.5rem 0.5rem;
  margin-left: 0;
}

.projectSlider {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 0.5rem;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.controlBtn {
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  color: green;
  background-color: rgba(0, 0, 0, 0.5);

  .sliderImgCountBox {
    display: flex;
    align-items: center;
    border: none;
    padding: 0.5rem 1rem;
    bottom: 2rem;
    right: 2rem;
    border-radius: 0.25rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);

    .galleryIconImg {
      margin-right: 0.25rem;
    }
  }

  .shareBtn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    color: #fff;
    font-size: 1.25rem;
    /* border: 1px solid #fff; */
  }
}

@media (width<=576px) {
  .projectSlider {
    height: 260px;
  }
}
