.video-container {
  position: relative;
  width: 100%;
}

/* background: rgba(0, 0, 0, 0.7) url("building.jpg");
background-size: cover;
background-blend-mode: darken; */

/* .video-container video {
    height: 100%;
    position: absolute;
    padding: 0;
    margin: 0;
    width: 100%;
    object-fit: cover;
    background-blend-mode: darken;
} */

.content-on-top {
  position: relative;
  margin: auto;
  height: 100%;
  pointer-events: none;
  /* background-color: rgba(0, 0, 0, 0.2); */
  cursor: pointer;
}

.header-container {
  padding-top: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  @media (max-width: 768px) {
    padding-top: 0;
  }
}

.searchBar-container {
  width: 70%;
  z-index: 1;
  position: relative;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -64%);
  pointer-events: none;
  /* background: rgba(0, 0, 0, 0.65); */
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;

  @media screen and (max-width: 768px) {
    pointer-events: auto;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0;
  }

  @media screen and (width<=576px) {
    /* top: 70%; */
    padding: 0.5rem 0 !important;
    text-align: center;
    transform: translate(-50%, 110%);

    h1 {
      color: #fff;
      font-size: 1.5rem !important;
    }

    p {
      color: #fff;
    }
  }
}

.templateContainer {
  bottom: none;
  position: absolute;
  width: 100%;
  height: 100%;
}

.templateSliderContainer {
  height: 100%;

  button {
    display: none !important;
  }

  img {
    width: 100%;
    height: 100%;
    filter: brightness(50%);
  }

  video {
    width: 100%;
    /* height: 100vh; */
    filter: brightness(60%);
  }
}
