.awardsTabsContainer {
    display: flex;
    justify-content: center;
}

.awardCardContainer .awardCard {
    &:hover {
        box-shadow: 0 4px 1rem #f37535;
    }
}

.awardCardContainer .awardCard .award-img {
    overflow: hidden;
    border-radius: 0.5rem;
    padding: 0.5rem;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.awardCardContainer .awardCard .award-img img {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    transition: 0.5s;
}

.awardCardContainer .awardCard .award-img img:hover {
    transform: scale(0.955);
}

.tabBtn {
    padding: 0.25rem 0;
    color: #fff;
    font-weight: 700;
    padding-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;
    cursor: pointer;
    background-color: rgb(11, 44, 61);
}

.tabBtn_active {
    background-color: #ef750f;
}

.yearBtn {
    border-left: 2px solid #777;
    padding: 1rem;
    font-size: 0.75rem;
    margin-bottom: 1px;
    font: bold;
    font-weight: 600;
}

.activeYearBtn,
.yearBtn:hover {
    cursor: pointer;
    background-color: #ef750f;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .tabBtn {
        font-weight: 600;
        padding: 0.25rem 1rem;
    }

    .yearSideBarContainer {
        display: flex;
        justify-content: center;
        overflow-x: scroll;
    }

    .yearBtn {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0 0.25rem;
        padding: 0.5rem;
    }
}