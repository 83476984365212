.modelContainer {
  position: relative;
  height: 98%;
  background: rgba(0, 0, 0, 0.4);
  width: 100% !important;

  .ant-modal-content {
    position: static !important;
    background-color: transparent !important;
  }

  .ant-modal-close {
    top: 50px;
    background: rgba(255, 255, 255, 0.8);

    & hover {
      background: rgba(255, 255, 255, 0.8) !important;
      cursor: pointer;
    }
  }
}

.popUpCarouselContainer {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (width <=550px) {
    width: 90%;
  }
}

.controlBtnBox {
  pointer-events: none;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;

  .leftBtn,
  .rightBtn {
    pointer-events: all;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    color: #ef750f;
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
  }
}
