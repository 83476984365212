/* .infiniteSlider-container {
    width: 100%;
    height: 500px;
} */

.centerSlider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 2p solid red;
}

.sliderImg-container {
    width: 50%;
    flex-grow: 1;
    padding: 0 0.5rem;
    /* padding: var(--gap-between-card); */
    height: 100%;
}

/* https://codepen.io/ivanman/pen/rLjOGN */

/* .sliderImg-container:hover {
    flex-grow: 4.3;
    background-color: rgba(255, 69, 0, 0.4);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.9);
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition-duration: 2s;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, background-color 0.2s ease-in;
    width: 900px !important;
} */

.infiniteSlider-img {
    border-radius: 1rem;
    width: 100%;
    cursor: pointer;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 768px) {
        height: 220px;
    }
}

.slider-title {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
}

.styleForSliderLib {
    /* border: 2px solid yellow;  */
    height: 90%;
    width: 90%;
    margin: 0.25rem;
    padding: 0.2rem;
}

.styleForSliderLib div {
    height: 100%;
}


/* .scrollBtn {
    border: 2px solid red;
} */

.styleForSliderLib button {
    color: black;
    background-color: black;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    padding-top: 0.4rem;
    overflow: hidden;
}

/* .styleForSliderLib button {
    width: 0;
    height: 0;
    border: none;
    display: none;
} */

.styleForSliderLib button:hover {
    background-color: black;
}

/* .slider-title{
    width: 0;
    opacity: 0;    
} */

.slider-title {
    width: 100%;
    opacity: 0;
}

.sliderImg-container:hover>.slider-title {
    width: 100%;
    opacity: 1;
    transition: all 0.9s;
}