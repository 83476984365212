.notFoundPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    font-size: 3rem;
    color: #ef750f;
}

.notFoundImageContainer {
    width: 300px;
    height: 300px;
}

.notFoundImageContainer img {
    width: 100%;
    height: 100%;
}