.QuerySectionTemplate {
  position: relative;
  background: url("../../../assets/contactus1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding: 1rem;
  transition: background-image 1s ease, filter 0.3s ease; /* Transition for smooth effect */
  filter: grayscale(100%) brightness(100%);
}
.QuerySectionTemplate:hover {
  /* color: white; */
  filter: none !important;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: none;
  color: #97999a;
  border: none;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  /* width: 300px; */
  outline: none;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background: none !important;
  border: none !important;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .country-list .country-name {
  margin-right: 6px;
  color: black;
}
.react-tel-input .selected-flag .arrow {
  border-right: 3px solid transparent;
  /* border-top: 4px solid white; */
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list {
  width: 253px;
  max-height: 192px;
}
.react-tel-input .selected-flag .arrow {
  left: 25px;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 0rem !important;
}

.react-tel-input .selected-flag:focus {
  background: none;
}
.form-control:focus {
  box-shadow: none;
}

.QuerySectionTemplate:hover {
  filter: none !important;
}

.QuerySectionTemplate:hover h1,
.QuerySectionTemplate:hover .p-4,
.QuerySectionTemplate:hover input::placeholder,
.QuerySectionTemplate:hover .btn,
.QuerySectionTemplate:hover .react-tel-input .form-control {
  color: white !important;
}

.QuerySectionTemplate:hover .btn {
  background-color: #ffa65b !important;
}

.QuerySectionTemplate:hover .react-tel-input .form-control {
  color: white !important;
}

@media (min-width: 1200px) {
  .responsive-heading {
    font-size: 5rem;
    color: #898989;
    font-weight: bold;
    line-height: 1.2;
    font-family: "Poppins";
    letter-spacing: 0.05em;
  }
}

@media (max-width: 1199px) and (min-width: 1000px) {
  .responsive-heading {
    font-size: 4rem;
  }
}

@media (max-width: 999px) and (min-width: 800px) {
  .responsive-heading {
    font-size: 3rem;
  }
}

@media (max-width: 799px) {
  .responsive-heading {
    font-size: 1rem;
    text-align: center;
  }
}
