.dropDown {
  position: relative;
  height: 100%;
  /* font-size: 0.75rem; */
  /* font-weight: 700; */
}

.dropDownBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
  cursor: pointer;
}

.dropDownList {
  padding: 3px 0.5rem;
  background: #fff;
  border-radius: 0.5rem;
  z-index: 2;
}

.dropDownListBtn {
  /* background-color: rgba(0, 0, 0, 0.1); */
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
  cursor: pointer;
  text-wrap: nowrap;
  color: #000;
  position: relative;
  /* text-decoration: underline; */
}

.radioBoxBtn {
  padding: 0 0.25rem 0 0 !important;
}

.nestedList {
  max-height: 200px;
  overflow-y: scroll;
  /* position: absolute; */
  /* top: 0; */
  /* left: 100%; */
  /* background-color: white; */
}

.nestedList > ul {
  width: 100%;
  padding-left: 0;
  padding-right: 0.25rem;
}

.nestedList > ul > li {
  min-width: 128px;
  list-style-type: none;
  /* background-color: rgba(0, 0, 0, 0.1); */
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
  margin: 0.25rem 0;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
}

.nestedList > ul > li:hover {
  /* background-color: #000; */
  /* color: black; */
  text-decoration: underline;
}

.nestedList::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

.nestedList::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

.nestedList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}

.propertyDropDownBtn {
  display: block;
  color: #000 !important;

  @media screen and (max-width: 768px) {
    color: #fff !important;
    cursor: pointer;
  }
}

/* .propertyDropDownBtn:hover {
  color: #fff !important;
} */

@media screen and (max-width: 768px) {
  /* .mobHeader */
  .dropDownList {
    position: relative;
    background: #000;
  }

  button.fw-bold {
    margin-top: 0;
    padding: 0 1rem;
  }

  .dropDownBtn {
    padding: 0;
    color: #fff !important;
    font-weight: 450;
  }

  .dropDownListBtn {
    color: #fff;
    font-weight: 300;
    position: static;
  }
  .nestedList {
    position: static;
    background-color: yellow;
  }
}
