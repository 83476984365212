.darkBlueButton {
  background-color: rgba(255, 157, 74, 1);
  border: none;
  width: 100%;
  height: 40px;
  min-width: 150px;
  display: block;
  color: #fff;
  margin: 0.5rem 0;
  font-size: 90%;
  padding: 0.5rem 0.75rem;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: #fff;
    background-color: rgba(239, 117, 15, 1);
  }
}

.whiteButton {
  border: none;
  border-radius: 2rem;
  padding: 1rem;
  width: 40%;
  min-width: 150px;
  display: block;
  margin-top: 2rem;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

/* button {
     border: none;
     border-radius: 2rem;
     padding: 1rem;
     width: 40%;
     display: block;
     margin-top: 2rem;
 } */
