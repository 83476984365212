.downloadPopUpFormContainer{
    left: 0;
    top: 0;
    padding: 1rem;
    height: 100vh;

    .innerDownloadPopUpFormBox{
        position: absolute;
        width: 330px;
        max-width: 350px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 1rem;
        background-color: #fff;
        border-radius: .5rem;
    }
    .downloadFormContainer{
        position: relative;
        width: 100%;
    }

    .downloadFormBox{
        background-color: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        padding: 1.5rem ;
        .form{
            border: 2px solid blue;
        }

        .downloadFormHead{
            margin-bottom: 1rem;
            height: 60px;
            border-radius: .25rem;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
            padding: .25rem 0;

            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}