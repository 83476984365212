.similarProjectCardBox {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    border-radius: 0.75rem;
    cursor: pointer;

    & img {
        width: 100%;
        height: 160px;
        max-height: 180px;
        object-fit: cover;
        border-radius: 0.75rem 0.75rem 0 0;
        background-color: rgba(0, 0, 0, 0.07);
    }
}

.similarProjectCardDetailsBox {
    padding: 0.5rem;

    .address {
        font-size: 0.75rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.6);
        text-transform: capitalize;
    }

    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
    }

    .contactBtn {
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 0.75rem;
        border-radius: 0.25rem;
        font-weight: 500;
        padding: 0.25rem 1rem;

        &:hover {
            background-color: #ef750f;
            color: #fff;
        }
    }
}

@media (width<=576px) {
    .similarProjectCardBox {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        border-radius: 0.5rem;
    }
}