.homePage {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}


.marque-container {
    width: 100%;
    height: 200px;
}

.infiniteSlider-container {
    height: 500px;
}

.infiniteSlider-container button {
    display: none !important;
}