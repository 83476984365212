/* finalexplore.css */
/* .bgFinalExplore {} */

.card {
  position: relative; /* Enables absolute positioning of children */
  width: 19.25rem;
  height: 354px;
  display: flex;
  flex-direction: column; /* Ensures children are stacked vertically */
  justify-content: center; /* Centers the paragraph vertically */
  align-items: center; /* Centers children horizontally */
  padding: 12px;
  gap: 12px;
  border-radius: 20px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* .card::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 280px;
    height: 410px;
    border-radius: 20px;
    background: linear-gradient(-45deg, #ef750e 0%, #ef750e 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
} */
/* 
.card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #ef750e 0%, #ef750e 100%);
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
} */

.headingExplore {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.card p:not(.headingExplore) {
  font-size: 14px;
}

.card p:last-child {
  color: #e81cff;
  font-weight: 600;
}

.card:hover::after {
  filter: blur(30px);
}

.card:hover::before {
  transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
}

.exploreViewsDetailsBtn {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 9rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

@media (width<=768px) {
  .bgFinalExplore {
    /* margin-bottom: 5rem; */

    .card {
      height: 240px;
      border-radius: 0.5rem;
    }

    .card::before {
      background: none;
      width: auto;
      height: auto;
    }

    .headingExplore {
      font-size: 0.75rem;
    }
  }
}
.exploreViewsDetailsBtn1 {
  /* background-color: var(--global-btnBackground-color); */
  position: absolute;
  bottom: -3px;
  border: none;
  width: 90%;
  height: 40px;
  /* min-width: 284px; */
  display: block;
  color: #fff;
  margin: 0.5rem 0;
  font-size: 90%;
  padding: 0.5rem 0.75rem;
  left: 50%;
  transform: translate(-50%);
  /* width: 9rem; */
  border-radius: 4.25rem;
  margin-bottom: 1rem;
}
