.backgroundImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 550px;
  width: 100%;
  height: 100%;
  margin: 0;

  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  object-fit: cover !important;
  /* aspect-ratio: 20/6; */
  transition: 1s;
  background-position: bottom !important;
}

.ourFootPrintTextContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.innerDiv {
  background: transparent;
  display: flex;
  width: 90%;
  justify-content: space-between;

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-align: justify;
  }

  @media (width<=800px) {
    flex-direction: column;
    justify-content: center;

    & h1,
    p {
      text-align: center;
      width: 100% !important;
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (width<=800px) {
    width: 100%;
    justify-content: center;
  }
}

.button1 {
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 150px;
  width: 30%;
  height: 50px;
  margin: 10px;

  &:hover {
    background-color: var(--global-text-color);
    color: #fff;
  }
}

.hoverTemplateCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* max-width: 120px; */
  margin: 1rem 0;
  border-radius: 0.3rem;
}
/* 
.hoverTemplateCard:hover {
  transform: rotate(20deg);
  transition: ease-in-out 400ms;
} */

.cityNameImgContainer {
  /* position: relative; */
  /* background-color: #fff; */
  border-radius: 1rem;
  /* opacity: ; */

  /* transition: 0.1s; */
  /* width: 100px;
    height: 100px; */

  .cityImageBox {
    width: 100%;
    height: 100%;
  }
}
.city_text_paragraph {
  transform: rotate(270deg) scaleY(1); /* Rotates the text 90 degrees */

  color: white; /* Sets the text color to white */
  font-size: 35px; /* Adjust font size as needed */
  position: absolute; /* Allows for positioning the text */
  top: 50%;
  /* left: 50%; */
  transform-origin: center center;
  white-space: nowrap;
  text-align: center;
}

.hoverTemplateCard img {
  width: 100%;
  height: 100%;
  cursor: pointer !important;
  opacity: 0.7;
}

/* .hoverTemplateCard button {
    background-color: #f7f7f7;
    color: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;

    &:hover {
        background-color: var(--global-text-color);
        color: #fff;
    }
} */

@media (max-width: 768px) {
  .hoverTemplateCard {
    width: 90%;
  }

  .paragraph {
    font-size: 0.75rem;
    margin-top: 0.2rem;
  }
}
