.customInput input {
    width: 100%;
    margin: 0.5rem 0;
    font-size: 90%;
    padding: 0.5rem 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 40px;
    /* border-radius: 1rem; */
}


.customSelect select {
    width: 100%;
    margin: 0.5rem 0;
    font-size: 90%;
    padding: 0.5rem .75rem 0.5rem .75rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 40px;
    /* border-radius: 1rem; */
    color: rgba(0, 0, 0, 0.6);
}