.blogCard {
  font-size: 80%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

/* .blogCard:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem 4.5rem 0.25rem 4.5rem;
} */

.blogCard img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  /* border-radius: 0.25rem 3.5rem 0.25rem 3.5rem; */
}

.blogCard img:hover {
  transition: 0.7s;
  transform: scale(0.9);
}

.blogCardBtn {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4rem;
  /* color: var(--global-text-color); */
  color: white;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem;
  margin-top: 0.75rem;
  min-width: 100%;
  width: 100%;
  max-width: 15rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ef750f;
}

/* .blogCardBtn:hover {
    border: none;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
} */

.blogCardImageBox {
  width: 100%;
  height: 9.75rem;
  max-height: 9.75rem;
}

@media screen and (max-width: 768px) {
  .blogCard {
    font-size: 80%;
    /* margin-left: 1rem; */
    padding: 0.5rem;
    /* width: 90%; */
    height: 420px !important;
    border-radius: 0.5rem;
    font-size: 10px;
    line-height: 0.75rem;
    color: #525558;
  }

  .blogCardImageBox {
    height: 15rem !important;
    max-height: 15rem;
  }

  .blogCard img {
    border-radius: 0.5rem;
  }

  /* .blogCardBtn {
        border: 1px solid rgb(237 114 51);
        border-radius: .25rem;
        margin: 0.75rem 0;
        width: -3%;
        width: 60px;
        position: relative;
        left: 72%;
        top: -11%;
        margin-top: -76px;
    } */

  .blogCard:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
  }
}
