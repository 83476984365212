.copyrightMedia i {
    border: 2px solid red;
}

.custom-bg-dark {
    background-color: black !important;
}

.linksText {
    text-align: center;
    color: #fff;
    font-size: .75rem;
    font-weight: 400;
}

.copyRightstyle {
    text-align: center;
}