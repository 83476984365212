.careerPageContent {
  display: flex;
  padding: 1rem 0;
}

.aboutDesc,
.aboutDesc1 {
  text-align: justify;
  background-color: #000000;
  border-radius: 1rem;
  color: white;
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-right: 2px;
}

.aboutImg {
  width: 100%;
  height: 620px;
  object-fit: cover;
}

.bgNumber {
  color: #ef750f;
  font-weight: 900;
  font-size: 4rem;
}

.paraDesc {
  font-size: 14px;
  text-align: justify;
}

.activityCard {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  padding: 0.25rem 1.25rem;
  margin: 0.5rem 0;
}

@media only screen and (max-width: 768px) {
  .careerPageContent {
    & .activityCard {
      width: 100%;
      border-radius: 1rem;
    }

    .careerPageImgContainer {
      position: relative;
      height: 200px;
    }

    .aboutImg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .bgNumber {
      font-size: 1.5rem;
      font-weight: 900;
      margin: 0;
      color: #b0b0b0;
    }

    .paraDesc {
      font-size: 0.75rem;
      font-weight: 400;
      text-align: left;
    }
  }

  .aboutDesc {
    width: 100%;
    line-height: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 70%;
  }

  .aboutDesc1 {
    width: 100%;
    font-size: 70%;
    padding: 0.75rem;
    border-radius: 0.5rem;
    text-align: justify;
  }
}
