.newCustomDropDown {
    height: 100%;
    position: relative;
    z-index: 22;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem 0 0 0.25rem;

    & button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: none;
        background: transparent;
        width: 100%;
        height: 100%;
        font-size: 0.75rem;
        font-weight: 700;
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .newListContainer {
        position: absolute;

        left: 0;
        top: calc(100%);

        /* top: calc(100% + .25rem); */
        padding: 0.25rem;
        border-radius: 0.25 0.25rem 0.5rem 0.5rem;
        background-color: #fff;
        height: 16rem;
        overflow-y: scroll;
        width: 150px;
    }

    .newListCheckBoxBtn {
        display: flex;
        align-items: center;
        line-height: 0.75rem;
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
        margin-bottom: 0.25rem;
        border-radius: 0.25rem;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .newCheckBoxBtn {
        padding: 0 !important;
        margin-right: 0.25rem;
    }

    .countCheckedBox {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25 0.5rem;
        border-radius: 0.25rem;
        width: 1.5rem;
        font-weight: 400;
        color: #fff;
        background-color: #ef750f;
    }
}