* {
  padding: 0;
  margin: 0;
  /* transition-property: background-color, opacity; */
  /* transition-duration: 1s; */
  /* transition-timing-function: ease-in-out; */
  font-family: "Poppins", sans-serif;
  /* font-family: 'Nunito Sans', sans-serif !important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Merriweather', serif;
} */

/* p {
  font-family: "Nunito Sans", sans-serif;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-darkBlue,
.c-darkBlue {
  /* color: #001A72; */
  color: #ef750f;
  color: orangered;
}

.text-gray {
  color: rgba(0, 0, 0, 0.5);
}

.text-white {
  color: #fff;
}

.bg-darkBlue {
  background-color: #001a72;
  color: #ef750f;
}

input:focus {
  outline: none;
  /* border-color: #ff0000; */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

:root {
  --theme-color: #ef750f;
  --global-text-color: #ef750f;
  --primary-hover-color: #ef750f;
  --theme-text-color: "#ef750f";
  --global-heading-color: #ef750f;
  --global-background-color: green;
  --global-btn-color: orangered;
  /* --global-btnBackground-color: #001A72; */
  /* --global-btn-onHover: #0d6efd; */
  --global-btnBackground-color: #ef750f;
  --global-btn-onHover: orangered;
  --global-dark-btn-color: #0b2c3d;
  --global-logo-color: #ef750f;
}

.text-color {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;
}

.text-them-color {
  color: var(--global-text-color);
}

/* thems color 
    Dark:- #0B2C3D  reference link  https://www.templatemonster.com/wordpress-themes/realestate-real-estate-wordpress-theme-234848.html
    #0B2C3D
 */

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.text-white-8 {
  color: #888;
}

.del-underLine {
  text-decoration: none;
}

.del-list-style {
  list-style-type: none !important;
}

.U-roman {
  list-style-type: upper-roman !important;
}

.theme-bg {
  color: #ef750f;
}

.theme-text {
  color: #ef750f;
}

/* !----CSS BY AG----------------------- */
.btn-shadow-card {
  background: #ffffff;
  color: #888;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.fixBorder {
  border: 5px solid red !important;
}

.lineClamp1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
