:root {
  --primary: rgba(255, 255, 255, 0.85);
  --light: rgba(255, 255, 255, 0.5);
  --accent: #ef750f;
}

footer {
  background-color: black;
  font: 0.75rem helvetica, sans-serif;
  color: var(--light);
  display: flex;
  justify-content: center;
}

footer aside ul {
  list-style-type: none;
  padding: 0;

  & li {
    padding: 0.5rem 0;
    cursor: pointer;

    &:hover:not(:first-of-type) {
      animation: pulse 0.5s;
      color: var(--accent);
    }
  }

  & li:first-of-type {
    color: var(--primary);
    cursor: default;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: translate(0);
  }

  50% {
    transform: translate(-0.1rem, -0.25rem);
  }
}

.contactWithUs {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.contactWithUs a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.contactWithUs a:hover {
  text-decoration: underline;
}

.contactWithUs li {
  text-wrap: nowrap;
  list-style-type: none;
  text-decoration: none;
}

.footer-logo {
  width: 160px;
  height: auto;
}

.footerAside {
  width: 100%;
  border-radius: 1rem;
  padding-left: 23px;
  background: rgba(18, 18, 18, 1);
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.15);
}

aside .category li {
  font-weight: 400;
  font-size: 0.8rem;

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    color: var(--accent) !important;
  }

  .heading-footer {
    font-size: 1rem;
    font-weight: 500;
    line-height: 2rem;
    border-bottom: 2px solid #ef750f;
  }
}

@media (max-width: 800px) {
  footer {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@media (width<=768px) {
  .footer-logo {
    width: 100%;
  }
}

@media (width<=575px) {
  .heading-footer {
    color: #ef750f;
  }
}

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* main {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--accent);

    & nav {
        position: fixed;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        & .logo {
            font-size: 1.25rem;
        }

        & .menu {
            font-size: 1.5rem;
        }
    }

    &>header {
        position: relative;
        height: 70vh;
        display: grid;
        align-content: center;
        text-align: center;
        gap: 0.5rem;
        font: 1rem helvetica, sans-serif;
        border: 5px solid red !important;

        & p {
            font-size: 0.8rem;
            font-style: italic;
        }

        & span {
            position: absolute;
            bottom: 2rem;
            left: 50%;
            transform: translateY(-50%);
            width: 13px;
            height: 13px;
            background-color: black;
            border-radius: 50%;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 11px;
                height: 11px;
                border: 1px solid black;
                border-radius: 50%;
                cursor: pointer;
            }

            &::before {
                left: -18px;
            }

            &::after {
                left: 18px;
            }
        }
    }
} */

/* footer header {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--primary);
    padding: 0 2rem;
    border: 10px solid green !important;

    & p {
        font-style: italic;
        line-height: 1.8;
        color: var(--light);
        border: 2px solid red !important;
    }

    & .icon {
        font-size: 1.1rem;
        padding: 0.25rem 0.5rem 0.25rem 0;
        cursor: pointer;
        color: var(--accent);

        &:hover {
            animation: pulse 0.5s;
            color: var(--light);
        }
    }
} */

/* footer aside {
    width: 100%;
    border: 2px solid blue;
} */

/* footer aside ul {
    width: 100%;
    padding: 0;
} */
