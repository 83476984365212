.fullPositionDesc p span strong {
  /* line-height: 115%; */
  margin-bottom: 0.0001pt;
  color: #ef750f;
  margin-bottom: 15px;
  text-align: center;
}
.activecards {
  background: #ef750f;
  padding: 9px;
  border-radius: 8px;
  color: white;
}
