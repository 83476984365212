.disclaimer-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
}

.disclaimer-content {
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.8);
    padding-top: 10px;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.acknowledge-button {
    padding: .25rem 1rem;
    background: var(--global-btnBackground-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}