.connectivityContainer {
    height: 19rem;
    max-height: 25rem;
    overflow-y: auto;
}

.locationGoogleMap {
    width: 100%;
    height: 100%;
    /* height: 20.75rem; */
}

.connectivityBox {
    height: 2.5rem;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;

    & img {
        /* width: 100%; */
        object-fit: cover;
        padding: 0.25rem;
        height: 100%;
        margin: 0.25rem;
        margin-right: 1rem;
    }

    .connectivityText {
        font-size: 1rem;
        color: #666;
        padding: 0 1rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media (width<=1000px) {
    .connectivityContainer {
        height: auto;
        max-height: none;
        overflow-y: none;
    }
}

@media (width<=576px) {
    .connectivityContainer {
        margin-top: 1rem;
    }

    .locationGoogleMap {
        height: 300px;
    }
}