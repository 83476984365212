.customDropDown {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  z-index: 999999;
}

.customDropDown button {
  width: 100%;
  height: 100%;
  /* line-height: 1.25rem; */
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 0.5rem 1rem;
  /* font-size: 0.75rem; */
  border: none;
}

.dropDown-MenuList {
  display: none;
}

.dropDown-MenuList_active {
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 700px;
  opacity: 1;
  transition: display 1s ease-in-out;
  /* overflow-y: scroll; */
  z-index: 99999;
  height: 350px;
  max-width: 800px;
  background-color: #fff;
  border-radius: 0.5rem;
  display: block;
}

.customDropDown .MenuItem {
  /* line-height: 1.25rem; */
  font-size: 0.75rem;
  border-radius: 0.25rem;
  margin: 0.25rem;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  transition: 0ms;
  /* background-color: rgba(0, 0, 0, 0.1); */
  color: black !important;
}

/* .customDropDown .MenuItem:hover {
  background-color: black;
  color: #fff;
} */
.customDropDown .MenuItem a {
  color: black !important;
}

/* .customDropDown .MenuItem a:hover {
  color: #fff !important;
} */
.customDropDown .MenuItem:hover {
  /* background-color: black !important; */
  text-decoration: underline;
}

.dropDown-MenuList_active::-webkit-scrollbar {
  width: 4px;
}

.dropDown-MenuList_active::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

.dropDown-MenuList_active::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}

.icon {
  line-height: 0.5rem;
  font-size: 0.75rem;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

/* ! */

@media screen and (max-width: 768px) {
  .mobNavBar .customDropDown {
    position: relative;
    left: 0;
    transform: translateX(-13%);
    height: 100%;
    z-index: 999999;
  }

  .mobNavBar .dropDown-MenuList_active {
    position: static;
    width: 100%;
    background: none;
  }

  .mobNavBar .navbar-brand {
    margin: 0;
    display: block;
    font-size: 0.75rem;
    /* 9px in rem */
    color: white;
  }

  .mobNavBar .fa-chevron-right:before {
    content: "\f054";
    /* color: #f; */
    color: #fff;
  }

  .mobNavBar .customDropDown .MenuItem {
    line-height: 0.25rem;
    transition: all 0.3s ease-in-out;
    background: none;
  }

  .mobNavBar .customDropDown .MenuItem:hover {
    background-color: #f37535;
    color: white;
    text-shadow: 0 0 5px white;
  }

  .customDropDown .MenuItem a {
    color: white !important;
  }

  .customDropDown .MenuItem a:hover {
    color: #fff !important;
  }

  .customDropDown .MenuItem:hover {
    background-color: black !important;
  }

  .mobNavBar .dropDown-MenuList_active .MenuItem {
    background: none;
  }

  .mobNavBar .dropDown_dropDownList {
    position: static;
    background: none;
  }

  .mobNavBar .dropDown_dropDownList {
    position: static;
    background: none;
  }
}