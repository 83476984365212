.positionTitle {
  font-weight: 500;
  font-size: 1rem;
  background: #000;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.5rem;
}

.joinUsCard {
  border: 1px solid #212529;
  border-radius: 1rem;
  padding: 1.25rem;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
}

.positionLocation {
  margin-left: 0px;
  font-size: 15px;
  font-weight: 600;
}

svg.locationIcons {
  font-size: 40px;
}

/* ol.fullPositionDesc li {
  font-size: 41px !important;
  text-align: justify;
  border: 2px solid red !important;
} */

.applyButton {
  float: right;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.applyButton:hover {
  color: #ef750f;
}

.ArrowButton {
  font-size: 60px;
}

.anchorColor {
  color: #ef750e;
}

.setAllButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.FulltimeStyle {
  background-color: #000;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  color: #fff;
  font-size: 0.75rem;
}

.filterTabs {
  /* margin-top: 20px; */
  text-align: center;
}

.filterTabs button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

/* .filterTabs button:hover {
  background-color: #ddd;
} */

/* .filterTabs button:focus {
  outline: none;
  background-color: #ccc;
} */

.readMoreButton {
  background: none;
  border: none;
  color: #ef750e;
  cursor: pointer;
  padding: 0;
  margin-top: 10px;
  display: block;
}

.readMoreButton:hover {
  text-decoration: none;
}

.departmentBtn {
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  color: #fff;
  text-decoration: none;
  font-size: 0.75rem;
  cursor: pointer;
  margin: 0.5rem;
  text-wrap: nowrap;
  background: #ef750f;
}

.departmentBtnActive {
  background: #000;
}

.departmentBtn:hover {
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.positionDescription {
  max-height: 70px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.positionDescription.expanded {
  max-height: 200px;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .positionTitle {
    font-weight: 500;
    font-size: 1rem;
    background: #000;
    color: #fff;
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.5rem;
  }

  .joinUsCard {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 0.75rem;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }

  .filterTabs {
    display: flex;
    text-align: center;
    overflow-x: scroll;
  }

  .filterTabs button {
    width: 100% !important;
    /* width: 200px; */
    font-size: 80%;
    font-size: 0.75rem !important;
  }
}
