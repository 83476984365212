.side-bar-main {
  margin: 0 0.5rem;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 0.5rem;
  cursor: pointer;
  /* border: 2px solid green !important; */
}
.side-bar-recent-main {
  /* margin: 0 0.5rem;
  width: 100%; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  /* padding: 0 0.5rem; */
  /* cursor: pointer;
  height: 80px; */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.recent-blog-post {
  height: 80px;
}
.blog-recent-post-text {
  /* width: 20ch; */
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-bar-container-recent {
  height: 100%;

  /* padding: 0.25rem; */
}

.side-bar-container-recent img {
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  object-fit: cover;
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4); */
  cursor: pointer;
}

.categories-blog-container {
  padding: 0.5rem 0;
  width: 100%;
  /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.15); */
}

.categories-heading {
  padding: 0.25rem;
  padding-left: 0.5rem;
  cursor: pointer;
}

.sideInfoContainer {
  padding: 0;
  padding-left: 0.5rem;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .blog-background {
    background: #fde3d7 !important;
  }
  .blog-recent-post-text {
    font-size: 10px;
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
  }
  .recent-blog-post {
    height: 100px;
  }
}
