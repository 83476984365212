.footer-text-content li {
    text-decoration: none;
    list-style: none;
    margin-top: 1rem
}

.customFontSize {
    font-size: 0.75rem !important;
}

.disClaimer {
    text-align: center;
}

.disclaimerOrList li {
    list-style: decimal;
}