/* .projectDetailsBox {
    border: 5px solid green;
} */

.stickyProjectNavBar {
    background-color: #0b2c3d;
    border-radius: 0.25rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    top: 90px;
    z-index: 1;
    overflow-x: auto;

    .projectNavBarBtn {
        font-weight: 550;
        font-size: 0.75rem;
        word-spacing: 2px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-wrap: nowrap;
    }

    .projectNavBarBtn_Active {
        background-color: #fff;
        color: #000;
        border-radius: 0.25rem;
    }

    .projectNavBarBtn:hover {
        cursor: pointer;
    }
}

.sectionShift {
    margin-top: 108px !important;
}

.projectSectionWrapper {
    border-radius: 1rem;
    /* padding: "6rem !important"; */
    box-shadow: 0 0 12px rgba(0, 0, 0, .3);
    border: 1px solid rgba(0, 0, 0, .1);
}

@media (width<=576px) {
    .projectSectionWrapper {
        border-radius: .5rem;
        box-shadow: 0 0 8px rgba(0, 0, 0, .15);
    }
}