.Wc-mission {
  width: 100%;
  height: auto;
  border-radius: 60px 0px 0px 0px;
  transition: border-radius 0.5s ease-in-out;
}

.Wc-mission:hover {
  border-radius: 60px 60px 60px 60px;
}

.WcSecBg1 {
  background-color: #d6dfd9;
  padding: 2rem;
  border-radius: 1.5rem;
  overflow: hidden;

  @media (width<=575px) {
    border-radius: 1rem;
    background-color: #d6dfd9 !important;
    padding: 1rem;
  }
}

.Wc-title {
  font-size: 2rem !important;
  font-weight: 700;
  border-bottom: 6px solid #72a081;
  display: inline;

  @media (width<=575px) {
    font-size: 1rem !important;
  }
}

.aboutSectionPara {
  margin: 1rem 0;
  text-align: justify;
}

.iconPlacement {
  float: right;
}

/* .mtb {
    margin-top: 60px;
    margin-bottom: 60px;
} */

@media screen and (max-width: 768px) {
  .Wc-title {
    border-bottom: none;
    color: #ef750f;
  }
}
