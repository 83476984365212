.wishlistBtn {
    color: #EF750F;
}

.closeBtn {
    border: none;
    padding: 0.5rem 0.75rem;
    color: #ef750f;
    font-weight: 700;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    z-index: 1;
}