.eventHeader {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: center;
}

.eventHeader a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  padding-left: 0.5rem;
  /* display: block; */
}

.events {
  /* background: #ef750f; */
  /* background: #0b2c3d; */
  font-size: 1.125rem;
  border-radius: 6.25rem;
  padding: 0.8125rem;
  margin-right: 0.5rem;
  text-align: center;
  cursor: pointer;
}

/* .events:hover {
  background: #ef750f !important;
} */

.successFullEvent {
  background: #0b2c3d;
  padding: 0.25rem 0;
  text-align: center;
}

/* .allEvent {

    padding: 0.25rem 0;
    margin-right: 0.5rem;
    text-align: center;
    cursor: pointer;
} */

.eventCardContainer a {
  /* display: flex; */
  text-decoration: none;
  color: #0b2c3d;
}
@media screen and (max-width: 768px) {
  .events {
    padding: 0px;
  }
}
